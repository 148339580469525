@import "../../../../../../sass/imports";
@import './NutritionAnalysis.scss';

.restaurant-menu {
    margin: 0 -10px;
    background-color: $super-light-gray;
    max-width: 971px;
    position: relative;

    @include min-screen(47em) {
        margin: -20px -10px;
        width: calc(100vw - 60px);
    }

    @include max-screen(47em) {
        &:before {
            @include transform(translate(-50%,calc(-100% + 40px)));
        }
    }

    .loading {
        display: flex;
        justify-content: center;
        min-height: 10em;
        align-items: center;

        i {
            display: block;
            width: 40px;
            height: 40px;
            font-size: 40px;
        }
    }

    > header {
        position: relative;
        z-index: 1;
        text-align: center;
        max-width: 760px;
        margin: auto;

        @include min-screen(47em) {
            text-align: left;
        }
    }

    > footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: white;
        text-align: center;
        border-radius: 0px 0px 10px 10px;
    }

    .nutrition-analysis h3 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin: 0 0 0.5em 0;
        color: $text-gray;
    }

    .open-filters-btn {
        border: none;
        background: none;
        font-size: 20px;
        padding: 15px;
        color: $text-gray;
    }

    .return-to-locations-btn {
        color: white;
        font-size: 15px;
        border: none;
        background: none;
        padding: 10px 20px;

        @include min-screen(47em) {
            color: $raspberry;
            padding: 15px 30px 10px 0px;
        }
    }

    .location-info {
        margin: auto;

        @include min-screen(47em) {
            border-bottom: 1px solid $inactive-text-gray;
            margin: 5px auto 0;
            padding: 0 0 10px 0;
        }
    }

    .product-results-list {
        padding-bottom: 50px;
        margin: 0 auto;
        max-width: 760px;
        background: #ECEFF1;

        @include min-screen(47em) {
            overflow: auto;
        }

        > header {
            max-width: 480px;
            padding: 5px;
            display: flex;

            @include min-screen(520px) {
                margin: auto;
            }
        }

        > footer {
            text-align: center;
            padding: 20px 0;
        }

        .browser-pager {
            @include outline-button($raspberry);
            @include border-radius(0);
            background-color: transparent;
            font-size: 12px;
            text-transform: uppercase;
            padding: 8px 10px;
            border-width: 0px;
            text-decoration: underline;
            text-align: center;
            margin: 5px;
        }


        .product-keyword-search {
            position: relative;
            width: calc(100% - 50px);
        }

        .open-filters-btn {
            border: none;
            background: none;
            font-size: 20px;
            padding: 5px 15px;
            color: $text-gray;
        }
    }

    .product-result-item {
        clear: both;
        color: $text-navy;
        font-size: 14px;
        margin: 0;
        padding: 15px 40px 15px 20px;
        border-bottom: 1px solid #d8d8d8;
        cursor: pointer;
        @include transition(all 0.1s ease-in-out);

        h4 {
            font-size: 12px;
            font-weight: bold;
            color: $text-navy;
        }

        p {
            > span {
                &:after {
                    @include icon-font;
                    content: $icon-bullet;
                    font-size: 6px;
                    display: inline-block;
                    margin: 0 5px;
                    vertical-align: top;
                    padding: 5px 0 0 0;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        &[data-choice-type="smart"] {
            background-color: #c4eadc;

            .choice-type {
                color: #18a371;
            }
        }

        &[data-choice-type="ok"] {
            background-color: #fbf3e3;

            .choice-type {
                color: $bluey-gray;
            }
        }
    }

    .select-product-btn {
        float: left;
        width: 30px;
        height: 30px;
        margin: 0 12px 10px 0;
        background: none;
        border: 1px solid $bluey-gray;
        @include border-radius(30px);

        &[data-selected="true"] {
            background-image: radial-gradient(circle at 50% 50%, #57f1b9, #14bd80);
        }
    }

    .product-stats {
        float: right;
        white-space: nowrap;
        text-align: right;

        .choice-type {
            margin: 0 0 0 10px;
            white-space: normal;
            width: 42px;
            font-size: 13px;
            display: inline-block;
            text-align: left;
            vertical-align: top;
            line-height: 1.1;
            font-weight: bold;
        }

        .kcal {
            display: inline-block;
            width: 40px;
            text-align: right;
            font-size: 15px;
            font-weight: bold;
        }
    }

    .product-result-container{
        display: inline-block;
        max-width: calc(100% - 175px);
    }

    .outer-map-container, .outer-results-container {
        @include min-screen(47em) {
            display: inline-block;
            vertical-align: top;
        }
    }

    .outer-map-container {
        @include min-screen(47em) {
            width: 60%;
            @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.25));
        }
    }

    .outer-results-container {
        @include min-screen(47em) {
            width: 40%;
            position: relative;

            &:before {
                content: ' ';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 10px;
                background-color: red;
                width: 100%;
                height: 150px;
                background: linear-gradient(to bottom, rgba(255,255,255,1) 69%,rgba(255,255,255,0) 100%);
            }
        }
    }

    .inner-results-container {
        padding: 0 20px 100px 20px;

        @include min-screen(47em) {
            padding: 150px 50px 50px 50px;
            max-height: 590px;
            overflow-y: auto;
        }
    }

    .nutrition-analysis {
        padding: 15px 20px 15px 20px;
        text-align: center;
        max-width: 760px;
        overflow: auto;
        white-space: nowrap;

        margin: auto 20px;

        @include min-screen(800px) {
            margin: auto;
        }
    }


    .create-meal-btn, .ok-btn, .cancel-btn {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 1.2px;
        color: white;
        background-color: $raspberry;
        @include border-radius(40px);
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(163, 0, 99, 0.2);
        padding: 9px 22px;
        border: none;
    }

    .report-issue-btn, .ok-btn, .cancel-btn {
        margin: 15px;
    }

    .cancel-btn {
        background-color: $bluey-gray;
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
    }

    .ok-btn:disabled {
        opacity: 0.5;
    }

    .report-issue-btn {
        border: none;
        background: none;
        color: $raspberry;
        font-size: 14px;
        padding: 15px;
        margin: 0;

        @include min-screen(47em) {
            margin: 0 40% 0 0;
        }
    }


}
