
@import "../../../../../sass/imports";

.restaurant-locations, .restaurant-menu {
    @include max-screen(47em) {
        &:before {
            content: ' ';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 50%;
            width: 145vw;
            height: 205px;

            @include transform(translate(-50%,calc(-100% + 115px)));
            @include border-radius(50%);

            @include background-image(linear-gradient(0deg, #5b7287, #324253 50%));
            @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.5));
        }
    }
}

.map-keywords-filter, .product-keyword-search, .full-browser-keyword-search, .recipe-keywords-search {
    position: relative;

    input {
        width: calc(100% - 40px);
        border-radius: 40px;

        border: none;
        @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.2), true);
        font-size: 13px;
        padding: 11px 0px 11px 40px;
        color: $text-gray;
        @include transition(box-shadow 0.25s ease-in-out);
        -webkit-appearance: none;

        &:focus {
            @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.3));
        }

        @include placeholder {
            color: darken($text-gray, 10%);
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    &:before {
        position: absolute;
        top: 50%;
        left: 8px;
        @include transform(translateY(-50%));
        @include icon-font;
        content: $icon-search3;
        color: $raspberry;
        font-size: 18px;
        padding: 5px 10px 5px 5px;
        pointer-events: none;
    }

    .clear-search-btn {
        position: absolute;
        top: 50%;
        right: 0;
        @include transform(translateY(-50%));
        font-size: 12px;
        padding: 12px;
        color: $text-gray;
        opacity: 0.0;
        @include transition(opacity .25s ease-in-out);
        border: none;
        background: none;
    }

    &[data-has-terms="true"] {
        .clear-search-btn {
            opacity: 1;
        }
    }

}

// .full-browser-keyword-search:before {
//     color: $raspberry;
// }

// .add-swap-favorites {
//     .full-browser-keyword-search:before {
//         display: block;
//     }
// }

.nutrition-disclaimer {
    h2 {
        text-align: center;
        font-size: 20px;
        margin: 15px 0;
        color: $text-navy;
    }

    p {
        color: $text-navy;
        font-size: 16px;
    }
}
