
$breakpoint: 47em;
/**
 * Style Guide classes: TEMPORARY LOCATION: should be moved to docs/sass classes
 */

.el-fonts {
    color: $el-grayish-blue;

    h1, h2, h3, h4, h5, h6, p {
        em {
            font-weight: bold;
        }
    }

    h1 {
        font-size: 48px;
        margin: 0 0 10px 0;
    }

    h2 {
        font-size: 38px;
        margin: 0 0 8px 0;
    }

    h3 {
        font-size: 32px;
        margin: 0 0 4px 0;
    }

    h4 {
        font-size: 24px;
        margin: 0 0 4px 0;
    }

    h5 {
        font-size: 20px;
        margin: 0 0 4px 0;
    }

    h6 {
        font-size: 16px;
        margin: 0 0 4px 0;
    }

    .t1 {
        font-size: 22px;
    }

    .t2 {
        font-size: 18px;
    }

    .t3 {
        font-size: 14px;
    }

    .t4 {
        font-size: 12px;
    }

    .t5 {
        font-size: 11px;
    }

    .t6 {
        font-size: 10px;
    }

    p, .p1 {
        font-size: 12px;
    }

    .p2 {
        font-size: 14px;
    }

    .p3 {
        font-size: 16px;
    }

    .p4 {
        font-size: 18px;
    }
 }

.el-form {
    .error-msg, .success-msg {
        font-size: 12px;
        line-height: 26px;
        text-align: center;
        margin: 8px 0;
    }

    .error-msg {
        color: $el-error;
    }

    .success-msg {
        color: $el-success;
    }
}

.el-error-msg {
    color: $el-error;
}

.el-list-form {

    .el-list-field {
        > .with-label {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #edeff2;
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;
        }
    }

    .with-label {
        position: relative;

        > label {
            display: inline-block;
            padding: 10px 0;
            font-weight: bold;
            font-size: 14px;
            line-height: 26px;
            color: $dark-slate-gray;
            @include min-screen(47em) {
                font-size: 16px;
            }
            em {
                font-weight: 300;
            }
        }

        > input {
            border: none;
            display: inline-block;
            line-height: 40px;
            text-align: right;
            color: $raspberry;
            font-size: 14px;
            background-color: inherit;
            flex-grow: 1;

            @include min-screen(47em) {
                font-size: 16px;
            }

            @include placeholder {
                color: #81909F;
            }

            &:disabled {
                opacity: 0.5;
            }
        }

        > input[type="number"] {
            float: none;
            text-align: right;
            font-family: monospace;
            font-weight: bold;
            color: $raspberry;
            font-size: 14px;
            background-color: inherit;

            @include min-screen(47em) {
                font-size: 16px;
            }
        }

        > textarea {
            width: 100%;
            border: none;
            font-size: 15px;
            font-weight: normal;
            letter-spacing: 0.25px;
            padding: 13px;
            color: $text-gray;
            -webkit-appearance: none;
            border: 1px solid #EDEFF2;
            min-height: 15em;
            @include border-radius(7px);

            &:focus {
                @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.3));
            }

            @include placeholder {
                text-transform: uppercase;
                font-size: 13px;
            }
        }

        .select-container {
            p.value, p.placeholder {
                font-size: 14px;
            }
        }

        .el-select-container {
            background: #fff;
            border-radius: 0;
            box-shadow: none;
            display: inline-block;
            position: relative;
            width: auto;
            flex-grow: 1;
            color: $raspberry;
            background-color: inherit;

            .select-dropdown {
                right: 0;
                width: max-content;
                left: auto;
            }

            &[data-state="true"] {
                border: 0px !important;
            }

            &[data-focus="true"] {
                border: 0px !important;
            }

            &[data-disabled="true"] {
                opacity: 0.5;
            }

            > p {
                text-align: right;
            }

            .value {
                color: $raspberry;
                padding: 10px 20px;
            }

            .placeholder {
                padding: 10px 20px;
            }

            .chevron {
                padding: 0;
            }
        }

        > em {
            position: absolute;
            bottom: 5px;
            right: 15px;
            @include transform(translateY(-50%));

            font-size: 10px;
            text-transform: uppercase;
            color: $text-gray;
        }

        .explanation {
            .triangle, .triangle2 {
                left: 50%;
            }

            .dropdown-btn {
                color: $raspberry;
                padding: 0 3px;
                border: none;
                background: none;
            }

            .dropdown-dropdown {
                @include transform(translate(-50%, -5px));
            }

            .dropdown-content {
                min-width: 250px;

                p {
                    color: $text-navy;
                    font-size: 14px;
                    margin: 0.75em 0;
                }
            }
        }

        &[data-error="true"] {
            border-bottom: 1px solid #E51919;

            label {
                color: #E51919;
            }
        }
    }

    .error-msg {
        text-align: left;
    }
}

// inputs
.el-labeled-input, .el-unlabeled-input {
    > label {
        display: block;
        font-size: 14px;
        line-height: 26px;
        margin: 0 0 0 0;
        text-align: left;
        color: $el-grayish-blue;
    }

    > input[type="text"], .el-input-container > input[type="text"],
    > input[type="number"], .el-input-container > input[type="number"],
    > input[type="password"], .el-input-container > input[type="password"],
    > textarea, .el-input-container > textarea,
    .react-tel-input > input[type="tel"],
    .pseudo-input {
        width: 100%;
        box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
        -webkit-appearance: none;
        border-radius: 10px;
        background-color: $el-white;
        border: 1px solid transparent;
        color: $el-grayish-blue3;

        font-size: 14px;
        line-height: 26px;
        padding: 7px 10px;

        &:focus {
            border: 1px solid $el-raspberry !important;
        }

        &:disabled {
            background-color: $el-gray4;
            color: $el-gray2;
            border: 1px solid transparent;
        }

        @include placeholder {
            color: #98A2BA; // @todo COLOR IS NOT IN PALETTE
        }
    }

    .el-input-char-limit {
        text-align: right;
        font-size: 12px;
        line-height: 14px;
        margin: 4px 10px 0 10px;
        color: $el-grayish-blue4;
    }

    .react-tel-input > input[type="tel"] {
        padding: 7px 10px 7px 40px;
    }

    input[type="number"] {
        text-align: right;
    }

    .el-input-icon {
        margin-left: -30px;
        cursor: pointer;
    }

    &[data-error="true"] {
        > input[type="text"], input[type="number"], input[type="password"], textarea, .pseudo-input,
        .el-select-container {
            border: 1px solid $el-error;
            background-color: rgba($el-error, 0.1);
        }

        > p {
            color: $el-error;
            font-size: 12px;
            line-height: 14px;
            margin: 4px 10px 0 10px;
        }
    }

    &[data-complete="true"] {
        > input[type="text"], input[type="number"], input[type="password"], textarea, .pseudo-input {
            border: 1px solid $el-success;
            background-color: rgba($el-success, 0.1);
        }
    }
}

.el-input-with-unit {
    .el-input-container {
        display: flex;
        align-items: center;

        input {
            flex: 10 1;
        }

        em {
            flex: 1 1;
            text-align: center;
        }
    }
}

.el-input-with-icon {
    position: relative;

    input[type="text"], input[type="number"], input[type="password"], textarea {
        padding: 7px 10px 7px 40px;
    }

    .left-icon {
        position: absolute;
        top: 13px;
        left: 15px;
        font-size: 18px;
        color: $el-raspberry;
    }
}

.el-search-input {
    input[type="text"], input[type="number"], input[type="password"], textarea {
        border-radius: 40px;
    }
}


// Circle Icons
.el-small-circle-icon {
    color: white;
    background-color: $el-green;
    border-radius: 50px;
    padding: 5px;
    font-size: 25px;
}


// buttons
.el-text-btn {
    font-size: 16px;
    line-height: 23px;
    border: none;
    background: none;
    color: $el-raspberry;
    cursor: pointer;
    text-decoration: none;
}

.el-large-btn, .el-medium-btn, .el-small-btn {
    text-align: center;
    text-transform: uppercase;
    border-radius: 25px;
    margin: 0 10px;
    transition: border 0.25s, background-color 0.25s, color 0.25s;
    white-space: nowrap;

    @include min-screen($breakpoint) {
        margin: 0 20px;
    }

    &:disabled {
        opacity: 0.5;
    }
}

.el-btn-icon-left {
    i {
        display: inline-block;
        margin: 0 10px 0 0;
    }
}

.el-btn-icon-right {
    i {
        display: inline-block;
        margin: 0 0 0 10px;
    }
}

.el-btn-badge-left, .el-btn-badge-right {
    position: relative;

    .el-btn-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18px;
        width: 18px;
        background-color: $el-orange;
        border-radius: 18px;
        color: $el-white;
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
    }
}

.el-btn-badge-left {
    &.el-medium-btn {
        padding: 6px 16px 6px 32px;
    }

    .el-btn-badge {
        left: 3px;
    }
}

.el-btn-badge-right {
    &.el-medium-btn {
        padding: 6px 32px 6px 16px;
    }

    .el-btn-badge {
        right: 3px;
    }
}


.el-btn-icon-alone {
    i {
        display: inline-block;
    }
}

.el-full-width-btn {
    width: 100%;
}

.el-wide-btn {
    @include min-screen($breakpoint) {
        min-width: 200px;
    }
}

.el-medium-wide-btn {
    @include min-screen($breakpoint) {
        min-width: 150px;
    }
}

.el-link-raspberry-btn {
    border: 0px;
    text-transform: none;
    background-color: transparent;
    text-decoration: underline;
    color: $el-raspberry;

    &:hover {
        color: $el-raspberry4;
    }

    &:focus {
        color: $el-raspberry4;
    }
}

.el-link-no-underline-raspberry-btn {
    border: 0px;
    text-transform: none;
    background-color: transparent;
    color: $el-raspberry;

    &:hover {
        color: $el-raspberry4;
    }

    &:focus {
        color: $el-raspberry4;
    }
}

.el-large-btn {
    font-size: 20px;
    line-height: 24px;
    padding: 8px 16px;
    text-align: center;
}

.el-medium-btn {
    font-size: 14px;
    line-height: 17px;
    padding: 6px 16px;
    text-align: center;
}

.el-small-btn {
    font-size: 12px;
    line-height: 14px;
    padding: 0 16px;
    text-align: center;
}

.el-orange-btn {
    border: 1px solid $el-orange;
    background-color: $el-orange;
    color: $el-white;

    &:hover {
        background-color: $el-orange3;
        border: 1px solid $el-orange3;
    }

    &:focus {
        background-color: $el-orange4;
        border: 1px solid $el-orange4;
    }
}
.el-green-btn {
    border: 1px solid $el-green;
    background-color: $el-green;
    color: $el-white;

    &:hover {
        background-color: $el-green3;
        border: 1px solid $el-green3;
    }

    &:focus {
        background-color: $el-green4;
        border: 1px solid $el-green4;
    }
}
.el-raspberry-btn {
    border: 1px solid $el-raspberry;
    background-color: $el-raspberry;
    color: $el-white;

    &:hover {
        background-color: $el-raspberry3;
        border: 1px solid $el-raspberry3;
    }

    &:focus {
        background-color: $el-raspberry4;
        border: 1px solid $el-raspberry4;
    }
}
.el-blue-btn {
    border: 1px solid $el-blue;
    background-color: $el-blue;
    color: $el-white;

    &:hover {
        background-color: transparent;
        color: $el-blue;
    }

    &:focus {
        background-color: $el-raspberry4;
        border: 1px solid $el-raspberry4;
    }

}
.el-grayish-blue-btn {
    border: 1px solid $el-grayish-blue;
    background-color: $el-grayish-blue;
    color: $el-white;

    &:hover {
        background-color: $el-grayish-blue3;
        border: 1px solid $el-grayish-blue3;
    }

    &:focus {
        background-color: $el-grayish-blue4;
        border: 1px solid $el-grayish-blue4;
    }
}
.el-gray-btn {
    border: 1px solid $el-grayish-blue2;
    background-color: $el-grayish-blue2;
    color: $el-grayish-blue4;

    &:hover {
        background-color: $el-grayish-blue3;
        border: 1px solid $el-grayish-blue3;
        color: white;
    }

    &:focus {
        background-color: $el-grayish-blue4;
        border: 1px solid $el-grayish-blue4;
        color: white;
    }
}

.el-white-btn {
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    color: $el-grayish-blue4;

    &:hover {
        background-color: $el-grayish-blue3;
        border: 1px solid $el-grayish-blue3;
        color: white;
    }

    &:focus {
        background-color: $el-grayish-blue4;
        border: 1px solid $el-grayish-blue4;
        color: white;
    }
}

.el-grayish-blue-outline-btn {
    border: 1px solid $el-grayish-blue;
    background-color: $el-white;
    color: $el-grayish-blue;

    &:hover {
        border: 1px solid $el-grayish-blue3;
        background-color: $el-grayish-blue3;
        color: white;
    }

    &:focus {
        border: 1px solid $el-grayish-blue4;
        background-color: $el-grayish-blue4;
        color: white;
    }
}

.el-raspberry-outline-btn {
    border: 1px solid $el-raspberry;
    background-color: $el-white;
    color: $el-raspberry;

    &:hover {
        border: 1px solid $el-raspberry3;
        background-color: $el-raspberry3;
        color: white;
    }

    &:focus {
        border: 1px solid $el-raspberry4;
        background-color: $el-raspberry4;
        color: white;
    }
}

.el-light-raspberry-outline-btn {
    border: 1px solid $el-raspberry;
    background-color: $el-raspberry5;
    color: $el-raspberry;

    &:hover {
        background-color: $el-raspberry3;
        border: 1px solid $el-raspberry3;
        color: white;
    }

    &:focus {
        background-color: $el-raspberry4;
        border: 1px solid $el-raspberry4;
        color: white;
    }
}

.el-blue-outline-btn {
    border: 1px solid $el-blue;
    background-color: transparent;
    color: $el-blue;

    &:hover {
        background-color: $el-blue;
        color: white;
    }

    &:focus {
        background-color: $el-blue;
        color: $el-grayish-blue;
    }
}
.el-white-outline-btn {
    border: 1px solid $el-white;
    background-color: $el-grayish-blue;
    color: $el-white;

    &:hover {
        background-color: $el-white;
        color: $el-grayish-blue;
    }

    &:focus {
        background-color: $el-grayish-blue2;
        color: $el-grayish-blue;
    }
}

// toggles
.el-flat-pill-toggle-btn {
    font-size: 12px;
    border: 1px solid $el-gray3;
    background-color: $el-gray3;
    color: $text-gray;

    transition: border 0.25s, background-color 0.25s, color 0.25s;
    padding: 6px 11px;
    border-radius: 25px;
    margin: 0 10px 10px 0;
    white-space: nowrap;

    &[data-active="true"] {
        color: $el-white;
        background-color: $el-grayish-blue;
        border: 1px solid $el-grayish-blue;
    }
}

.el-toggle-btn {
    background-color: rgba(66, 85, 105, 0.1);
    @include border-radius(40px);
    padding: 5px 10px;
    display: inline-block;
    border: none;
    -webkit-appearance: none;
    margin: 5px;
    font-size: 14px;
    color: $text-navy;

    &[data-active="true"] {
        background-color: $text-navy;
        color: white;
        font-size: 15px;
        font-weight: bold;
    }
}

.el-toggle-switch {
    display: flex;
    position: relative;
    border: 1px solid $light-gray-border;
    overflow: hidden;
    background-color: $el-grayish-blue1;
    border: none;
    padding: 7px 10px 5px 10px;
    @include border-radius(30px);
    font-size: 12px;

    span {
        width: calc(50% - 10px);
        white-space: nowrap;
        position: relative;
        z-index: 10;
        text-transform: uppercase;
        display: inline-block;
        margin: 0 5px;
    }

    &:before {
        content: ' ';
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: 5;
        width: calc(50% - 4px);
        height: calc(100% - 4px);
        @include border-radius(30px);
        @include transition(left .1s ease-in-out);
        background-color: $el-grayish-blue;
    }

    span:first-child {
        color: white;
        margin: 0 15px 0 5px;
    }

    span:last-child {
        color: $el-grayish-blue;
        margin: 0 3px 0 15px;
    }

    &[data-active="true"] {
        &:before {
            left: calc(2px + 50%);
            background-color: $el-raspberry;
        }

        span:first-child {
            color: $el-grayish-blue;
        }

        span:last-child {
            color: white;
        }
    }
}

// modals
.el-modal {

  .el-modal-ok-btn, .el-modal-cancel-btn {
        font-size: 14px;
        line-height: 24px;
        padding: 6px 33px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 25px;
        margin: 0 10px;
        transition: border 1s, background-color 1s, color 1s;

        @include min-screen($breakpoint) {
            min-width: 200px;
            margin: 0 20px;
        }

        i {
            display: inline-block;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    .el-modal-cancel-btn {
        border: 1px solid $el-grayish-blue;
        background-color: white;

        color: $el-grayish-blue;

        &:hover {
            background-color: $el-grayish-blue3;
            color: white;
            border: 1px solid $el-grayish-blue3;
        }

        &:focus {
            background-color: $el-grayish-blue4;
            color: white;
            border: 1px solid $el-grayish-blue4;
        }
    }

    .el-modal-ok-btn {
        border: 1px solid $el-raspberry;
        background-color: $el-raspberry;

        color: white;

        &:hover {
            background-color: $el-raspberry3;
        }

        &:focus {
            background-color: $el-raspberry4;
        }
    }

    .el-modal-close-x {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        z-index: 10;

        border: none;
        background: none;
        padding: 20px;
        color: $el-grayish-blue;
    }

    .el-modal-back-btn {
        color: white;

        border: none;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        background: none;
        position: relative;
        z-index: 20;
        font-size: 14px;

        @include min-screen($breakpoint) {
            padding: 4px 20px 4px 15px;
            border: 1px solid white;
            @include border-radius(20px);
        }

        &:before {
            content: $icon-arrow-left;
            @include icon-font;
            display: inline-block;
            margin: 0 10px 0 0;

            @include min-screen($breakpoint) {
                content: $icon-chevron-left;
            }
        }

        > span {
            display: none;

            @include min-screen($breakpoint) {
                display: inline;
            }
        }
    }

    .el-modal-controls {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-modal-footer {
        display: flex;
        justify-content: space-around;
        width: 100%;

        .el-modal-ok-btn, .el-modal-cancel-btn {
            height: 40px;
            width: 40%;
            margin: 0;
            display: inline-block;
            font-size: 14px;
            margin-bottom: 0;

            @include max-screen(47em) {
                height: 66px;
                width: 50%;
                border-radius: 0;
            }
        }

        .el-modal-cancel-btn {
            color: #324253;
            background: #B5BEC8;
            border: 0;
            @include min-screen(47em) {
                background: #B5BEC8;
                color: #324253;
            }
        }
    }
}

.el-modal-overlay {
    background: rgba(72, 80, 90, 0.9);
    backdrop-filter: blur(10px);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 10;

    opacity: 0;
    @include transition(all 0.333s ease-in-out);

    &.ReactModal__Overlay--after-open {
        opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
        opacity: 0;
    }

    &.el-modal3-bottom {
        display: flex;
        justify-content: center;
        align-items: center;

        @include max-screen(470px) {
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-start;
        }
    }
}

.el-modal-container {
    .el-modal-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        min-height: 35vh;
        text-align: center;

        .icon-spinner2 {
            display: block;
            color: $raspberry;
            width: 50px;
            height: 50px;
            font-size: 50px;;
        }
    }

    .el-confirmation {

        h1 {
            color: #425569;
            font-size: 20px;
            line-height: 1.25;
            margin: 0.75em;
            max-width: 500px;
            text-align: center;
        }

        h2 {
            color: #425569;
            font-size: 20px;
            line-height: 1;
            margin: 0.75em;
            text-align: center;
        }

        p {
            color: #626571;
            font-size: 1em;
            margin: 0.75em;
            text-align: center;
        }

    }
}

.el-modal-body-container {
    max-width: 100vw;

    > .el-alert {
        display: flex;
    }
}

/**
 * EL Modal Type Specific Classes
 */
.el-modal1 {
    // Modal1 absolutely positions the container, not the modal itself.
}

.el-modal2 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    @include min-screen($breakpoint) {
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        @include transform(translate(-50%, -50%));
    }
}

.el-modal3 {
    &.el-modal3-fixed {

        .el-modal3-container {
            @include border-radius(20px 20px 0 0);
            min-width: 100vw;
            position: absolute;
            bottom: 0;
            left: 0;

            @include min-screen(400px) {
                top: 50%;
                left: 50%;
                bottom: auto;
                @include transform(translate(-50%, -50%));
            }

            @supports (padding-bottom: constant(safe-area-inset-bottom)) {
                padding-bottom: constant(safe-area-inset-bottom);
            }

            @supports (padding-bottom: env(safe-area-inset-bottom)) {
                padding-bottom: env(safe-area-inset-bottom);
            }

            @include min-screen(400px) {
                border-radius: 10px;
                min-width: 400px;
                max-width: calc(100vw - 45px);
            }

            @include min-screen(47em) {
                max-width: 600px;
            }
        }
    }

    &.el-modal3-photo-lightbox {

        .el-modal3-container {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;

            min-width: calc(100vw - 45px);
            @include border-radius(0px);

            display: flex;
            flex-direction: column;

            @include min-screen(445px) {
                min-width: 400px;
            }

            @include min-screen(47em) {
                max-width: calc(100vw - 45px);
                max-height: calc(100vh - 45px);
                top: 50%;
                left: 50%;
                bottom: auto;
                @include transform(translate(-50%, -50%));
            }

            > header {
                flex: 0;
                padding: 0;

                .close-x-circle {
                    position: absolute;
                    right: 20px;
                    top: 25px;
                    width: 31px;
                    height: 31px;
                    background-color: rgba(255, 255, 255, 0.5);
                    border-radius: 50%;
                    z-index: 9;

                    .el-modal-close-x {
                        padding: 5px 8px 20px 20px;

                        .icon-x {
                            font-weight: 900;
                        }
                    }
                }
            }

            > footer {
                padding: 10px;
                flex: 0;
            }
        }

        .el-modal3-body-container {
            padding: 0;
            overflow: auto;
            flex: 50 1;

            > img {
                width: 100%;
            }

            > footer {
                button {
                    @include max-screen(500px) {
                        min-width: 90px;
                    }
                    min-width: 120px;
                }
            }
        }
    }

    &.el-modal3-centered {
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: auto;
        @include transform(translate(-50%, -50%));

        .el-modal3-container {
            min-width: calc(100vw - 45px);
            @include border-radius(20px);

            @include min-screen(445px) {
                min-width: 400px;
                max-width: calc(100vw - 45px);
            }

            @include min-screen(47em) {
                max-width: 650px;
            }
        }
    }

    &.el-modal3-bottom {
        .el-modal3-container {
            min-width: calc(100vw - 45px);
            @include border-radius(20px);

            @include min-screen(470px) {
                min-width: 400px;
                max-width: calc(100vw - 45px);
            }

            @include min-screen(47em) {
                max-width: 600px;
            }

            @include max-screen(470px) {
                border-radius: 20px 20px 0 0;
            }
        }
    }
}

.el-modal1-container {
    background-color: white;

    @include box-shadow(0px, 4px, 12px, 12px, rgba(0, 0, 0, 0.15));
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    @supports (padding-top: constant(safe-area-inset-top)) {
        padding-top: constant(safe-area-inset-top);
        padding-bottom: constant(safe-area-inset-bottom);
    }

    @supports (padding-top: env(safe-area-inset-top)) {
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
    }

    @include min-screen($breakpoint) {
        @include border-radius(10px);
        top: 60px;
        left: 50%;
        right: auto;
        @include transform(translate(-50%, 0));
        max-width: calc(100vw - 40px);
        max-height: calc(100vh - 80px);
    }

    @include max-screen($breakpoint) {
        &:before {
            content: ' ';
            display: block;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 50%;
            @include transform(translate(-50%,calc(-100% + 110px)));
            @include border-radius(50%);

            @include background-image(linear-gradient(0deg, #5b7287, #324253 50%));
            @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.5));

            width: 145vw;
            height: 370px;

            @supports (top: constant(safe-area-inset-top)) {
                top: calc(0 + constant(safe-area-inset-top));
            }

            @supports (top: env(safe-area-inset-top)) {
                top: calc(0 + env(safe-area-inset-top));
            }

            @include min-screen($breakpoint) {
                display: none;
            }
        }
    }

    > header {
        position: relative;
        z-index: 2;
        margin: 0 0 -45px 0;
        min-height: 100px;

        @include min-screen($breakpoint) {
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 20;
            bottom: 100%;
            margin: 10px 0;
            width: 100%;
            max-height: 60px;
            min-height: auto;

            &[data-has-controls="false"] {
                display: block;
                min-height: 30px;

                .el-modal-back-btn {
                    position: absolute;
                }

                > h2 {
                    margin: 0 100px;
                }
            }
        }

        > h2 {
            color: $el-white;
            text-align: center;
            flex: 5 1;
            font-size: 17px;
            text-transform: uppercase;

            @include max-screen($breakpoint) {
                margin: 25px auto 10px auto;
                max-width: calc(100vw - 90px)
            }
        }
    }

    > footer, .el-modal1-container-footer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 30px;
        text-align: center;
        flex: 1 1;
    }

    .el-modal-back-btn {
        @include max-screen($breakpoint) {
            position: absolute;
            //Increase clickable area
            padding: 20px 0px 5px 25px;
            top: 5px;
            left: 0;
            font-size: 18px;
        }
    }
}

.el-modal2-container, .el-modal3-container {
    background-color: white;

    @include box-shadow(0px, 4px, 12px, 12px, rgba(0, 0, 0, 0.15));
    display: flex;
    flex-direction: column;

    @include min-screen($breakpoint) {
        max-width: 600px;
        min-width: 420px;
        max-height: calc(100vh - 10px);

        @supports (max-height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom))) {
            max-height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 20px);
        }

        @supports (max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))) {
            max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 20px);
        }
    }

    > header {
        position: relative;
        padding: 20px 0 0 0;
        flex: 1 1;

        h2 {
            font-size: 24px;
            font-weight: bold;
            line-height: 29px;
            color: $el-grayish-blue;
            padding: 10px 50px;
            text-align: center;
        }
    }

    > footer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 30px;
        text-align: center;
        flex: 1 1;
    }
}

.el-modal2-container {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 5px;
    right: 5px;
    @include border-radius(10px);
    background-color: $el-gray5;

    @supports (top: calc(20px + constant(safe-area-inset-top))) {
        top: calc(20px + constant(safe-area-inset-top));
        bottom: calc(20px + constant(safe-area-inset-bottom));
    }

    @supports (top: calc(20px + env(safe-area-inset-top))) {
        top: calc(20px + env(safe-area-inset-top));
        bottom: calc(20px + env(safe-area-inset-bottom));
    }

    @include min-screen($breakpoint) {
        position: relative;
        top: auto; bottom: auto;
        left: auto; right: auto;
    }

    > header, > footer {
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        position: relative;
    }

    > header {
        padding: 10px 0;

        .el-modal-close-x {
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
        }
    }

    > footer {
        padding: 20px;
    }
}

.el-modal3-container {
    width: 100vw;

    > footer {
        @include max-screen(470px) {
            flex-direction: column-reverse;
            align-items: stretch;
            row-gap: 20px;
        }
    }
}

.el-modal1-body-container {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 50;
    padding: 20px 10px;

    @include max-screen($breakpoint) {
        padding: 65px 10px 20px 10px;
    }

    @include min-screen($breakpoint) {
        @include border-radius(10px);
    }
}

.el-modal2-body-container, .el-modal3-body-container {
    flex: 50;
}

.el-modal2-body-container {
    overflow-y: auto;
    padding: 20px 10px;
}

.el-modal3-body-container {
    padding: 20px;
}

.el-modal3-photo-lightbox-body-container {
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @include max-screen(600px) {
        max-height: calc(100vh - 150px);
    }

    img {
        width: 100%;
    }
}

// fancy boxes
.el-fancy-box {
    padding: 30px;
    background: white;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 16px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &:has(> footer) {
        padding: 30px 30px 0 30px;
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 30px 0;
        text-align: center;
    }
}

.el-input-with-select {
    display: flex;

    .input {
        flex: 5;
        > input {
            border-radius: 10px 0 0 10px;
            border-right: none;
        }
    }

    .select {
        flex: 3;
        > .el-select-container {
            border-radius: 0 10px 10px 0;
            border-left: none;
            padding: 0;
        }
    }
}

.el-select-container, .el-combobox-container {
    width: 100%;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
    border-radius: 10px;
    display: inline-block;
    position: relative;
    text-align: left;
    vertical-align: top;

    .chevron {
        background: none;
        border: none;
        color: #203152;
        display: inline-block;
        font-size: 8px;
        padding: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    &[data-focus="true"] {
        border: 1px solid $el-raspberry;
    }

    .active-dropdown-btn {
        border: none;
        width: 100%;
        height: 100%;
        background: none;
        z-index: 1;
        position: relative;
    }

    .type-ahead {
        bottom: 0;
        cursor: pointer;
        left: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    .value {
        padding: 7px 10px;
        font-size: 16px;
    }

    &.small {
        height: 24px;
        border-radius: 4px;
        vertical-align: middle;
        line-height: 10px;

        .value {
            font-size: 12px;
        }
    }

    &[data-state="true"] {
        border: 1px solid $el-raspberry !important;

        .select-dropdown, .combobox-dropdown {
            max-height: 15em;
            opacity: 1;
            overflow-y: auto;
            margin-top: 1px;
            padding-top: 5px;
        }
    }

    &[data-above="true"] {
        .select-dropdown, .combobox-dropdown {
            bottom: calc(100% + 5px);
            top: auto;
        }
    }

    .search {
        width: 100%;
        box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
        -webkit-appearance: none;
        border-radius: 10px;
        background-color: $el-white;
        border: 1px solid transparent;
        color: $el-grayish-blue3;

        font-size: 14px;
        line-height: 26px;
        padding: 7px 10px;

        &:focus {
            border: 1px solid $el-raspberry !important;
        }

        &:disabled {
            background-color: $el-gray4;
            color: $el-gray2;
            border: 1px solid transparent;
        }

        @include placeholder {
            color: #98A2BA; // @todo COLOR IS NOT IN PALETTE
        }
    }

    .active-dropdown-btn {
        border: none;
        width: 100%;
        height: 100%;
        background: none;
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }

    .value {
        padding: 7px 10px;
        font-size: 16px;
        color: $el-grayish-blue3;
    }

    .placeholder {
        padding: 7px 10px;
        font-size: 16px;
        color: $el-grayish-blue2;
    }

    .select-dropdown, .combobox-dropdown {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: #fff;
        border: 0.5px solid #e0e0e0;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        transition: opacity .15s ease-in-out;
        width: 100%;
        z-index: 25;

        .dropdown-content {
            padding: 0 0 10px 0;
            position: relative;
            z-index: 26;

            ul {
               .option {
                    color: #425569;
                    cursor: pointer;
                    padding: 5px 10px;
                    transition: all .1s ease-in-out;

                    &:hover {
                        background-color: #ECEFF1;
                        color: $el-raspberry;
                         >label {
                            color: $el-raspberry;
                        }
                    }

                    &[data-selected="true"] {
                        background-color: $el-raspberry;
                        color: #FFFFFF;
                        >label {
                            color: #FFFFFF;
                        }
                    }

                    &[data-highlighted="true"] {
                        color: red;
                        font-weight: bold;

                        >label {
                            color: red;
                        }
                    }

                    >label {
                        display: block;
                        color: #425569;
                        font-size: 11px;
                        font-weight: 400;
                        letter-spacing: normal;
                        padding: 5px 0 0;
                        text-align: left;
                        text-transform: none;
                        white-space: normal;
                    }
                }

                .sub-label-short {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    label {
                        color:  white;
                        font-size: 16px;
                        width: auto;
                        cursor: pointer;
                    }

                    &[data-selected="false"] {
                        label {
                            color: $raspberry;
                        }
                    }
                }
            }
        }

    }
}

.el-combobox-container {
    .type-ahead {
        opacity: 1;
        border: none;
        background: none;
        padding: 7px 10px;
        font-size: 16px;
        color: $el-grayish-blue3;

        @include placeholder {
            color: $el-grayish-blue2;
        }
    }
}

// Don't use this one - use Widgets/Popup.react instead.
.el-dropdown-popup {
    text-align: left;

    .dropdown-dropdown {
        .dropdown-container {
            .dropdown-content {
                padding: 20px 20px 10px;
                .close-btn {
                    font-size: 10px;
                }
            }
        }
    }

    .el-dropdown-popup-list {

        > li {
            cursor: pointer;
            font-size: 14px;
            margin: 15px 0;

        }
    }
}

.el-checkbox, .el-radio-btn {
    background: none;
    border: none;
    display: block;
    position: relative;
    margin: 0.8em 0 0.8em 20px;
    padding-left: 10px;
    position: relative;
    border: none;
    background: none;
    text-align: left;

    &:before {
        position: absolute;
        left: -20px;
        background: #FFFFFF;
        @include feather-font;
        content: $feather-check;
        box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
        border-radius: 4px;
        height: 24px;
        width: 24px;
        display: inline-block;
        color: #FFFFFF;
        font-size: 22px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &[data-locked="true"] {
        padding-left: 30px;
        &:after {
            @include icon-font;
            content: $icon-lock;
            position: absolute;
            line-height: 1.5;
            top: 0;
            left: 10px;
        }
    }

    &[data-active="true"] {
        &:before {
            box-shadow: none;
            background: $mint-green3;
        }
    }


    &[data-disabled="true"] {
        &:before {
            opacity: 0.5;
        }
    }
}

.el-radio-btn {
    &:before {
        border-radius: 24px;
        font-size: 17px;
    }
}
