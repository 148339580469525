@import "../../../../../../sass/imports";

.restaurant-locations {
    margin: 0 -10px;
    position: relative;

    @include min-screen(47em) {
        margin: -20px -10px;
        width: calc(100vw - 60px);
    }

    @include max-screen(47em) {
        &:before {
            @include transform(translate(-50%,calc(-100% + 115px)));
        }
    }

    .loading, .no-results-found {
        text-align: center;

        h2 {
            font-size: 18px;
            font-weight: bold;
            color: $text-navy;
            padding: 32px 0 23px 0;
        }

        p {

            font-size: 16px;
            color: $text-navy;
            &:last-of-type {
                margin-top: 10px;
            }
        }

        i {
            display: inline-block;
            color: $raspberry;
            font-size: 36px;
        }

        > footer {
            padding: 26px 0;
        }
    }

    .keyword-search {
        width: calc(100% - 10px);
        margin: 5px;
    }

    .location-result-item {
        border-bottom: .5px solid #d8d8d8;
        padding: 15px 0;
        clear: both;
    }

    .establishment-info {
        padding: 10px;
    }

    .location-result-item, .establishment-info {
        margin: 0;
        cursor: pointer;

        h3 {
            font-size: 15px;
            color: $text-navy;
            font-weight: bold;
        }

        p {
            color: #7c8a99;
            font-size: 13px;
        }

        .matching {
            float: right;
            display: inline-block;
            max-width: 75px;
            text-align: right;
            margin: 0 0 0 10px;

            color: $text-navy;
            font-size: 15px;
            font-weight: bold;
        }

        .yelp-price {
            font-size: 13px;
            em {
                color: $raspberry;
                font-weight: 800;
            }
        }
    }

    .location-results {
        position: relative;
    }

    .map-keywords-filter {
        z-index: 2;
        max-width: 335px;
        margin: 0 auto;
        position: relative;

        @include min-screen(47em) {
            position: absolute !important;
            left: calc(60% + 50px);
            top: 26px;
            width: 29vw;
        }
    }

    .outer-map-container, .outer-results-container {
        @include min-screen(47em) {
            display: inline-block;
            vertical-align: top;
        }
    }

    .outer-map-container {
        @include max-screen(47em) {
            position: relative;
        }

        @include min-screen(47em) {
            width: 60%;
            @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.25));
        }
    }

    .outer-results-container {

        @include min-screen(47em) {
            width: 40%;
            position: relative;

            &:before {
                content: ' ';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 10px;
                background-color: red;
                width: 100%;
                height: 150px;
                background: linear-gradient(to bottom, rgba(255,255,255,1) 69%,rgba(255,255,255,0) 100%);
            }
        }
    }

    .inner-results-container {
        padding: 0 20px 100px 20px;

        @include min-screen(47em) {
            padding: 150px 50px 50px 50px;
            max-height: calc(100vh - 80px);
            overflow-y: auto;
        }

        .report-issue-btn {
            float: right;
            margin: 0;
            padding: 0 0 0 20px;

            @include min-screen(47em) {
                position: absolute;
                z-index: 9;
                top: 0;
                right: 0;
                padding: 0 20px;
            }
        }
    }
}
